import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "../components/Faq/faqs.scss";
import GreenButton from "../components/Button/GreenButton";
import Layout from "../components/Layout";
import Helmet from "react-helmet";
import "../scss/404.scss";

const Error = () => {
  return (
    <Layout title="error page" description="error page">
      <Helmet>
        <link rel="alternate" href="https://www.firstpridethailand.com/wheretobuy/" hreflang="th-th" />
        <link rel="alternate" href="https://www.firstpride.sg/wheretobuy" hreflang="en-sg" />
      </Helmet>
      <div className="Error">
        <div className="topBanner"></div>
        <div className="faqsSection bottom404">
          <div className="faqsWrapper errorWrapper">
            <h1>404 PAGE NOT FOUND</h1>
            <div className="btnBox">
              <GreenButton icon="search" msg="Search" yellow={true} to="/" />
            </div>
          </div>
          <div className="faqsImg errorImage">
            <StaticImage src="../../img/faqs1.webp" placeholder="blurred" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Error;
